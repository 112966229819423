<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE EL CONFIGURADOR DE ENCUESTAS:
                    </span>
                    <hr>
                    <b-row class="content-justify-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class=" ico fas fa-question-circle fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaPreguntas.length}}</span>
                            <br>
                            <span class="text-muted">Preguntas registradas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de preguntas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col cols="6" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Satisfaccion Cliente'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col cols="6"  v-if="checkPermissions('010-MEJ-SAT-CON','c')==1" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarPregunta = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva pregunta
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Preguntas registradas </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table hover bordered show-empty mediun responsive outlined :items="listaPreguntas" :fields="camposPreguntas" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">

                                    <b-button   v-if="checkPermissions('010-MEJ-SAT-CON','u')==1" size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarPregunta(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button   v-if="checkPermissions('010-MEJ-SAT-CON','d')==1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarPregunta(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :show.sync="modalRegistrarPregunta" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva pregunta </span>
            </h6>
            <CButtonClose @click="modalRegistrarPregunta = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarPregunta)">
                <b-row>
                    <b-col md="9">
                        <validation-provider name="pregunta" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Pregunta:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la pregunta" v-model="datosPregunta.pregunta"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3" class="mt-2">
                        <b-form-group label="Con fichero: ">
                            <b-form-radio-group small plain v-model="datosPregunta.conFichero" :options="radioFichero"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="orden de encuesta" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Orden de encuesta:" class="mb-2">
                                <b-form-input size="md" type="number"   :state="getValidationState(validationContext)" placeholder="Ingrese el orden" v-model="datosPregunta.orden"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6" class="mt-2">
                        <b-form-group label="Tipo de pregunta: ">
                            <b-form-radio-group small plain v-model="datosPregunta.tipoPregunta" :options="radioTipoPregunta"></b-form-radio-group>
                        </b-form-group>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarPregunta = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarPregunta" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar pregunta </span>
            </h6>
            <CButtonClose @click="modalActualizarPregunta = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarPregunta)">
                <b-row>
                    <b-col md="9">
                        <validation-provider name="pregunta" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Pregunta:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la pregunta" v-model="datosActualizarPregunta.pregunta"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3" class="mt-2">
                        <b-form-group label="Con fichero: ">
                            <b-form-radio-group small plain v-model="datosActualizarPregunta.conFichero" :options="radioFichero"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="orden de encuesta" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Orden de encuesta:" class="mb-2">
                                <b-form-input size="md" type="number"   :state="getValidationState(validationContext)" placeholder="Ingrese el orden" v-model="datosActualizarPregunta.orden"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6" class="mt-2">
                        <b-form-group label="Tipo de pregunta: ">
                            <b-form-radio-group small plain v-model="datosActualizarPregunta.tipoPregunta" :options="radioTipoPregunta"></b-form-radio-group>
                        </b-form-group>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarPregunta = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    data() {
        return {
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],
            listaPreguntas: [],
            camposPreguntas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "orden",
                    label: "Orden",
                    class: "text-center"
                },
                {
                    key: "pregunta",
                    label: "Pregunta",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                },
            ],

            datosPregunta: {
                idCliente: '',
                pregunta: '',
                orden: 1,
                tipoPregunta: 1,
                conFichero: 1
            },

            datosActualizarPregunta: {
                idCliente: '',
                idPreguntaSatisfaccion: '',
                pregunta: '',
                orden: 1,
                tipoPregunta: 1,
                conFichero: 1
            },

            radioTipoPregunta: [{
                value: 1,
                text: "Desplegable SI/NO",
            }, {
                value: 2,
                text: "Valoración mediante estrellas"
            }],
            radioFichero: [{
                value: 1,
                text: "Si",
            }, {
                value: 2,
                text: "No"
            }],

            modalRegistrarPregunta: false,
            modalActualizarPregunta: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        registrarPregunta() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-pregunta-satisfaccion-cliente",
                    me.datosPregunta, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarPregunta = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarPreguntas();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarPregunta() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-pregunta-satisfaccion-cliente",
                    me.datosActualizarPregunta, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarPregunta = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarPreguntas();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPreguntas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-preguntas-satisfaccion-cliente", {
                        params: {
                            idCliente: me.datosPregunta.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaPreguntas = response.data;
                    me.filasTotales = response.data.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        abrirModalActualizarPregunta(param) {
            this.datosActualizarPregunta.idPreguntaSatisfaccion = param.item.idPreguntaSatisfaccion;
            this.datosActualizarPregunta.orden = param.item.orden;
            this.datosActualizarPregunta.tipoPregunta = param.item.tipoPregunta;
            this.datosActualizarPregunta.conFichero = param.item.hasFichero;
            this.datosActualizarPregunta.pregunta = param.item.pregunta;
            this.modalActualizarPregunta = true;
        },
        resetModalRegistrarPregunta() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosPregunta.orden = 1;
            this.datosPregunta.pregunta = '';
            this.datosPregunta.tipoPregunta = 1;
            this.datosPregunta.conFichero = 1;
        },
        resetModalActualizarPregunta() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        eliminarPregunta(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la pregunta?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-pregunta-satisfaccion-cliente", {
                                idPreguntaSatisfaccion: param.item.idPreguntaSatisfaccion
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarPreguntas();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }

    },
    computed: {

    },
    watch: {
        modalRegistrarPregunta: function (val) {
            if (val == false) {
                this.resetModalRegistrarPregunta();
            }
        },
        modalActualizarPregunta: function (val) {
            if (val == false) {
                this.resetModalActualizarPregunta();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosPregunta.idCliente = this.datosActualizarPregunta.idCliente = user.uidClient;
            this.listarPreguntas();
        }
    }

}
</script>
